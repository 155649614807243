@import 'functions/transitions.functions';
@import 'variables/text-field.variables';

$select-input-padding: calculate-rem(15px);

.base-select-style {
  background-color: $white;
  border: 1px solid $blue-40;
  border-radius: $border-radius;
  min-height: $text-field-height;
  height: $text-field-height;
  box-shadow: none;
  transition: apply-input-field-focus-animation(box-shadow);
}

.base-select-input {
  color: $black-100;
  font-family: $font-family-primary;
  font-style: normal;
  font-weight: $base-font-weight;
  font-size: calculate-rem(13px);
  line-height: calculate-rem(18px);
  caret-color: $vivid-blue-100;
}

.transparent-select-styles {
  background-color: transparent;
  border: none;
  min-height: $text-field-height;
  height: $text-field-height;
  box-shadow: none;
}

.transparent-select-input {
  background-color: transparent;
  border: none;
  color: $white;
  font-family: $font-family-primary;
  font-style: normal;
  font-weight: 700;
  line-height: calculate-rem(19px);
}

.base-dropdown-panel {
  font-family: $font-family-primary;
  font-style: normal;
  font-weight: $base-font-weight;
  font-size: calculate-rem(13px);
  margin-top: calculate-rem(4px);
  background: $white;
  border: 1px solid $blue-20;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: $border-radius;
  overflow-x: hidden;

  .ng-dropdown-panel-items {
    .ng-option {
      &.ng-option-marked,
      &.ng-option-selected {
        background-color: $blue-6;
      }

      &.ng-option-disabled {
        font-family: $font-family-primary;
        font-style: normal;
        font-weight: $base-font-weight;
        font-size: calculate-rem(13px);
        color: $black-100;
      }
    }
  }
}

.ng-select.custom-ng-select {
  margin-top: calculate-rem(8px);

  &.ng-select-multiple {
    .ng-select-container {
      @extend .base-select-style;

      .ng-value-container {
        height: 100%;
        display: flex;
        align-items: center;
        @extend .base-select-input;

        .ng-value-label {
          padding-left: calc(#{$select-input-padding} - 10px);
        }

        .ng-input {
          padding-left: $select-input-padding;

          > input {
            @extend .base-select-input;
          }
        }

        .ng-placeholder {
          @extend .base-select-input;
        }
      }

      .ng-arrow {
        border-color: $black-100 transparent transparent;
      }
    }
  }

  &.ng-select-single {
    .ng-select-container {
      @extend .base-select-style;

      .ng-clear-wrapper {
        color: $blue-40;
        text-align: center;

        &:hover {
          .ng-clear {
            color: $blue-40;
          }
        }

        .ng-clear {
          line-height: unset;
        }
      }

      .ng-value-container {
        height: 100%;
        display: flex;
        align-items: center;
        @extend .base-select-input;

        .ng-value-label {
          padding-left: calc(#{$select-input-padding} - 10px);
        }

        .ng-placeholder {
          @extend .base-select-input;

          padding-left: calc(#{$select-input-padding} - 10px);
          color: $black-60;
        }

        .ng-input {
          top: 50%;
          transform: translateY(-50%);
          padding-left: $select-input-padding;

          > input {
            font-size: calculate-rem(13px);
            @extend .base-select-input;
          }
        }
      }

      .ng-arrow {
        border-color: $black-100 transparent transparent;
      }
    }
  }

  &.ng-select-focused {
    > .ng-select-container {
      border-color: $vivid-blue-100;
      box-shadow: $text-field-focus-shadow;
    }
  }

  &.ng-select-opened {
    > .ng-select-container {
      border-radius: $border-radius;

      .ng-arrow {
        border-color: transparent transparent $black-100;
      }
    }
  }

  .ng-dropdown-panel {
    font-family: $font-family-primary;
    font-style: normal;
    font-weight: $base-font-weight;
    font-size: calculate-rem(13px);
    margin-top: calculate-rem(4px);
    background: $white;
    border: 1px solid $blue-20;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: $border-radius;
    overflow-x: hidden;

    .ng-dropdown-panel-items {
      .ng-option {
        padding-left: calculate-rem(23px);
        padding-right: calculate-rem(23px);
        &.ng-option-marked,
        &.ng-option-selected {
          background-color: $blue-6;
        }

        &.ng-option-disabled {
          font-family: $font-family-primary;
          font-style: normal;
          font-weight: $base-font-weight;
          font-size: calculate-rem(13px);
          color: $black-100;
        }
      }
    }
  }
}

.ng-select.filter-ng-select {
  &.ng-select-multiple {
    .ng-select-container {
      @extend .transparent-select-styles;
    }
  }

  &.ng-select-single {
    .ng-select-container {
      @extend .transparent-select-styles;

      .ng-value-container {
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 calculate-rem(24px);
        @extend .transparent-select-input;

        .ng-value-label {
          padding-left: calc(#{$select-input-padding} - 10px);
        }

        .ng-input {
          top: 50%;
          transform: translateY(-50%);
          padding-left: $select-input-padding;

          > input {
            @extend .transparent-select-input;
          }
        }

        .ng-placeholder {
          @extend .transparent-select-input;
        }
      }

      .ng-arrow-wrapper {
        position: absolute;

        .ng-arrow {
          border-color: $white transparent transparent;
        }
      }
    }
  }

  &.ng-select-focused {
    > .ng-select-container {
      border-color: transparent;
    }
  }

  &.ng-select-opened {
    > .ng-select-container {
      border-radius: $border-radius;

      .ng-arrow-wrapper {
        position: absolute;

        .ng-arrow {
          border-color: transparent transparent $white;
        }
      }
    }
  }

  .ng-dropdown-panel {
    @extend .base-dropdown-panel;
  }

  &.ng-select-disabled > .ng-select-container {
    background-color: $blue-3;
  }
}

.modal-frame {
  .ng-dropdown-panel {
    @extend .base-dropdown-panel;
  }
}
