@import '~theme/functions';
@import '~theme/variables/variables';
@import '~theme/variables/colors';

.auth-title {
  margin-bottom: calculate-rem(30px);
  font-size: calculate-rem(20px) !important;
  font-style: normal;
  font-weight: bold;
  color: $blue-100;
}

.page-header-title {
  font-family: $font-family-secondary;
  font-style: normal;
  font-weight: bold;
  font-size: calculate-rem(19px) !important;
  color: $blue-100;
  margin: 0 calculate-rem(20px);
}

.card-filter-title {
  font-family: $font-family-primary;
  font-weight: 700;
  color: $blue-100;
  font-size: calculate-rem(13px);
  margin-left: calculate-rem(7px);
  margin-bottom: 0;
}

.basic-white-normal-text {
  font-family: $font-family-primary;
  font-weight: 600;
  color: $white;
  font-size: calculate-rem(13px);
  margin-bottom: 0;
}

.basic-white-bold-text {
  font-family: $font-family-primary;
  font-weight: 700;
  color: $white;
  font-size: calculate-rem(13px);
  margin-bottom: 0;
}
