@import 'variables';
@import '../functions';

$filter-container-padding: calculate-rem(15px);
$card-filtering-container-padding: calculate-rem(15px);
$table-row-gap: calculate-rem(10px);

$filter-tag-height: calculate-rem(28px);
$filter-tag-color: $light-blue-100;
$filter-tag-border-radius: 40px;
$filter-tag-padding: calculate-rem(10px);
$filter-tag-max-width: calculate-rem(193px);
$filter-tag-margin-top: calculate-rem(10px);
$filter-tag-margin-right: calculate-rem(5px);

$filter-small-image-w-h: calculate-rem(20px);
$filter-small-image-margin-right: calculate-rem(12px);
$filter-small-image-border-radius: calculate-rem(40px);
