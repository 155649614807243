@import 'variables/checkbox.variables';

$table-border-color: $blue-20;
$table-sort-icon-size: calculate-rem(18px);

.custom-table {
  .ngx-datatable {
    .datatable-header {
      border-top: 1px solid $table-border-color;
      border-bottom: 1px solid $table-border-color;

      .datatable-header-inner {
        height: 100%;
        width: 100%;
      }

      .datatable-header-cell {
        display: flex;
        align-items: center;
        padding: 0 10px !important;
        border-right: 1px solid $table-border-color;
        text-align: center;

        &:first-of-type {
          border-left: 1px solid $table-border-color;
          padding: 6px !important;

          .datatable-header-cell-template-wrap {
            width: 100%;
            height: 100%;
          }
        }

        &:last-of-type {
          border-right: none;
        }

        .datatable-checkbox {
          display: none;
        }

        .datatable-header-cell-label {
          text-transform: uppercase;
          font-weight: 700;
          font-family: $font-family-primary;
          font-style: normal;
          font-size: calculate-rem(12px);
          line-height: calculate-rem(16px);
          color: $black-80;
        }
      }

      .sort-active {
        background: $blue-3;

        .datatable-header-cell-label {
          color: $blue-100;
        }
      }

      .datatable-icon-up::before {
        content: '';
        display: block;
        width: $table-sort-icon-size;
        height: $table-sort-icon-size;
        margin: 0 0 calculate-rem(5px) calculate-rem(9px);
        transform: rotate(-90deg);
        background: url('~assets/image/arrow.svg') top no-repeat;
      }

      .datatable-icon-down::before {
        content: '';
        display: block;
        width: $table-sort-icon-size;
        height: $table-sort-icon-size;
        margin-top: calculate-rem(5px);
        transform: rotate(90deg);
        background: url('~assets/image/arrow.svg') top no-repeat;
      }

      .datatable-icon-sort-unset::before {
        opacity: 0;
      }
    }

    .datatable-body {
      overflow: hidden;
    }

    .datatable-scroll {
      width: 100% !important;
    }

    .datatable-body-row {
      .datatable-body-cell {
        display: flex;
        align-items: center;
        padding: 0 calculate-rem(10px) !important;
        border-right: 1px solid $table-border-color;
        cursor: pointer;

        &:first-of-type {
          border-left: 1px solid $table-border-color;
          padding: 0 !important;

          .datatable-body-cell-label:first-of-type {
            height: $custom-checkbox-height;
          }
        }

        &:last-of-type {
          border-right: none;
        }

        .datatable-body-cell-label {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: $base-font-weight;
          font-family: $font-family-primary;
          font-style: normal;
          font-size: calculate-rem(13px);
          line-height: calculate-rem(18px);
          color: $black-100;

          .datatable-checkbox {
            display: none;
          }
        }
      }
    }

    .datatable-row-wrapper {
      border-bottom: 1px solid $table-border-color;
    }
  }
}

.loading-table-screen {
  position: absolute;
  top: #{$app-header-height};
  width: calc(100vw - #{$app-side-navigation-width});
  height: calc(100vh - #{$app-header-height});
  background-color: $light-white;

  &.open-side-filter {
    width: calc(100vw - #{$app-side-navigation-width} - #{$app-side-filter-width});
  }
}
